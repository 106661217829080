import React, { useCallback } from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { FileAttachmentUUID } from '../../../fileAttachmentsTypes';

import { DOWNLOAD_FILE_ATTACHMENT_QUERY } from '../../../queries/downloadFileAttachment.query';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';
import { useDownloadFileAttachment } from '../../../hooks/useDownloadFileAttachment';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { DownloadCache } from '../../../../downloads/DownloadCache';

interface DownloadFileAttachmentButtonProps {
  fileAttachmentUuid: FileAttachmentUUID;
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  tooltipSingleton?: boolean;
}

function DownloadFileAttachmentButton({
  fileAttachmentUuid,
  className,
  iconClassName,
  icon,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton,
  i18nText
}: DownloadFileAttachmentButtonProps) {
  const {
    downloadFileAttachmentLoading,
    downloadFileAttachmentErrorMessage,
    downloadFileAttachment
  } = useDownloadFileAttachment({
    query: DOWNLOAD_FILE_ATTACHMENT_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({ error: downloadFileAttachmentErrorMessage });

  const { downloadNanoId } = useDownloadNanoId();

  const handleClick = useCallback(() => {
    downloadFileAttachment({
      uuid: fileAttachmentUuid,
      deviceNanoId: downloadNanoId
    });
  }, [fileAttachmentUuid, downloadNanoId, downloadFileAttachment]);

  return (
    <PureTooltipIconButtonHelper
      className={className}
      i18nText={i18nText}
      iconClassName={iconClassName}
      icon={icon}
      tooltipI18nText={tooltipI18nText}
      tooltipPlacement={tooltipPlacement}
      disabled={downloadFileAttachmentLoading}
      onClick={handleClick}
      tooltipSingleton={tooltipSingleton}
    />
  );
}

export default DownloadFileAttachmentButton;
