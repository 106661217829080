import { ProjectNanoID } from '../../../projects/projectsTypes';
import { TaskNanoID } from '../../../tasks/tasksTypes';
import { WhiteboardKeyID } from '../../whiteboardsTypes';

import { ProjectPath } from '../../../projects/ProjectPath';
import { TaskPath } from '../../../tasks/TaskPath';

function getWhiteboardPath(whiteboardKeyId: WhiteboardKeyID): string {
  const taskNanoId = whiteboardKeyId.match(/task-(.*)-room/)?.[1] as TaskNanoID;

  const projectNanoId = whiteboardKeyId.match(
    /project-(.*)-room/
  )?.[1] as ProjectNanoID;

  return taskNanoId
    ? TaskPath.whiteboard(taskNanoId)
    : ProjectPath.whiteboard(projectNanoId);
}

export default getWhiteboardPath;
