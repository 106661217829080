import { useCallback } from 'react';

import { ClassName, FileUrl, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useDownloadModelButton } from './hooks/useDownloadModelButton';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface DownloadModelButtonProps {
  model: {
    file: FileUrl;
    name: string;
  };
  className?: ClassName;
  iconClassName?: ClassName;
  icon?: IconsEnum;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipSingleton?: boolean;
  tooltipPlacement?: TooltipPlacement;
}

function DownloadModelButton({
  model,
  className,
  iconClassName,
  icon,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  tooltipSingleton
}: DownloadModelButtonProps) {
  const { downloadModel } = useDownloadModelButton();

  const handleClick = useCallback(
    () => downloadModel(model),
    [downloadModel, model]
  );

  return (
    <PureTooltipIconButtonHelper
      className={className}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      onClick={handleClick}
      tooltipI18nText={tooltipI18nText}
      tooltipSingleton={tooltipSingleton}
      tooltipPlacement={tooltipPlacement}
    />
  );
}

export default DownloadModelButton;
