import dynamic from 'next/dynamic';

import { SendImagesToWhiteboardPageModalButtonProps } from './SendImagesToWhiteboardPageModalButton';

const LazySendImagesToWhiteboardPageModalButton = dynamic(
  () => import('./SendImagesToWhiteboardPageModalButton'),
  {
    ssr: false
  }
);

function SendImagesToWhiteboardPageDynamicModalButton(
  props: SendImagesToWhiteboardPageModalButtonProps
) {
  if (!LazySendImagesToWhiteboardPageModalButton) {
    return null;
  }

  return <LazySendImagesToWhiteboardPageModalButton {...props} />;
}

export default SendImagesToWhiteboardPageDynamicModalButton;
