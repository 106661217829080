import { ReactNode, useCallback, useEffect } from 'react';

import { ClassName } from '../../../../types';

import { useModelViewer } from '../../hooks/useModelViewer';

interface ModelViewerAnnotationProps {
  className?: ClassName;
  hotspotName: string;
  position?: string;
  normal?: string;
  children?: ReactNode;
  absolutePosition?: boolean;
}

function ModelViewerAnnotation({
  className,
  hotspotName,
  position,
  normal,
  children,
  absolutePosition
}: ModelViewerAnnotationProps) {
  const { modelViewerEl } = useModelViewer();

  const slot = `hotspot-${hotspotName}`;

  const updateHotspot = useCallback(() => {
    if (!modelViewerEl) return;

    const center = modelViewerEl.getBoundingBoxCenter();
    const size = modelViewerEl.getDimensions();

    const posParsed = position.trim().split(' ');

    const posX = parseInt(posParsed[0]) || 0;
    const posY = parseInt(posParsed[1]) || 0;
    const posZ = parseInt(posParsed[2]) || 0;

    modelViewerEl.updateHotspot({
      name: slot,
      normal,
      position: absolutePosition
        ? position
        : `${center.x + (size.x / 2) * posX} ${
            center.y + (size.y / 2) * posY
          } ${center.z + (size.z / 2) * posZ}`
    });
  }, [absolutePosition, modelViewerEl, normal, position, slot]);

  const onLoad = useCallback(() => {
    setTimeout(() => updateHotspot(), 0);
  }, [updateHotspot]);

  useEffect(() => {
    modelViewerEl?.loaded
      ? onLoad()
      : modelViewerEl?.addEventListener('before-render', onLoad);

    return () => {
      modelViewerEl?.removeEventListener('before-render', onLoad);
    };
  }, [modelViewerEl, onLoad]);

  useEffect(() => {
    updateHotspot();
  }, [modelViewerEl, updateHotspot]);

  return (
    <button
      className={className}
      slot={slot}
      data-position={position}
      data-normal={normal}
    >
      {children}
    </button>
  );
}

export default ModelViewerAnnotation;
