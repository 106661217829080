import React, { Fragment, memo, useCallback } from 'react';

import { FileUrl, ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import { TaskNanoID } from '../../../tasksTypes';

import {
  CreateMessageWithTaskNanoIdQueryResponse,
  CREATE_MESSAGE_WITH_TASK_NANO_ID
} from '../../../../messages/queries/createMessageWithTaskNanoId.query';

import { useCreateMessageWithTaskNanoId } from '../../../../messages/hooks/useCreateMessageWithTaskNanoId';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';
import {
  useImageEditor,
  ImageEditor,
  ImageEditorOnSubmit
} from '../../../../../helpers/ImageEditor';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { Files } from '../../../../../utils/Files';

interface EditImageAndSendToTaskModalButtonProps {
  taskNanoId: TaskNanoID;
  file: FileUrl;
  className?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  tooltipPlacement?: TooltipPlacement;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  toggleImageEditorBackdrop?: () => void;
  tooltipSingleton?: boolean;
}

function EditImageAndSendToTaskModalButton({
  taskNanoId,
  file,
  className,
  i18nText,
  tooltipI18nText,
  tooltipPlacement,
  icon,
  iconClassName,
  tooltipSingleton,
  toggleImageEditorBackdrop
}: EditImageAndSendToTaskModalButtonProps) {
  const { enabled, hideEditor, toggleEnabled } = useImageEditor({
    toggleBackdrop: toggleImageEditorBackdrop
  });

  const { createMessageWithTaskNanoId } =
    useCreateMessageWithTaskNanoId<CreateMessageWithTaskNanoIdQueryResponse>({
      taskNanoId,
      query: CREATE_MESSAGE_WITH_TASK_NANO_ID
    });

  const handleSubmit = useCallback<ImageEditorOnSubmit>(
    (data) => {
      return createMessageWithTaskNanoId({
        ...data,
        taskNanoId
      });
    },
    [createMessageWithTaskNanoId, taskNanoId]
  );

  if (!Files.isImage(file)) {
    return null;
  }

  return (
    <Fragment>
      <PureTooltipIconButtonHelper
        className={className}
        i18nText={i18nText}
        iconClassName={iconClassName}
        icon={icon}
        tooltipI18nText={tooltipI18nText}
        tooltipPlacement={tooltipPlacement}
        onClick={toggleEnabled}
        tooltipSingleton={tooltipSingleton}
      />
      <ImageEditor
        enabled={enabled}
        image={file}
        hideEditor={hideEditor}
        onSubmit={handleSubmit}
        uploadFileType="fileAttachment"
      />
    </Fragment>
  );
}

export default memo<EditImageAndSendToTaskModalButtonProps>(
  EditImageAndSendToTaskModalButton
);
