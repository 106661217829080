import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import each from 'lodash/each';
import { Vector3 } from 'three/src/math/Vector3';
import { Vector2D, Vector3D } from '@google/model-viewer/lib/model-viewer-base';
import { HotspotData } from '@google/model-viewer/lib/features/annotation';
import { ModelViewerElement } from '@google/model-viewer';

import { useModelViewer } from '../../../../hooks/useModelViewer';
import { toVector3 } from '../../../../utils/toVector3';
import { Units, unitsConfiguration } from '../../../../../../types';

function drawLine(
  svgLine: SVGLineElement,
  dotHotspot1: HotspotData,
  dotHotspot2: HotspotData
) {
  if (svgLine && dotHotspot1 && dotHotspot2) {
    svgLine.setAttribute('x1', dotHotspot1.canvasPosition.x.toString());
    svgLine.setAttribute('y1', dotHotspot1.canvasPosition.y.toString());
    svgLine.setAttribute('x2', dotHotspot2.canvasPosition.x.toString());
    svgLine.setAttribute('y2', dotHotspot2.canvasPosition.y.toString());
  }
}

function drawSvg(svgEl: SVGElement, modelViewerEl: ModelViewerElement) {
  each(
    svgEl?.querySelector('.dim')?.querySelectorAll('line'),
    (line, index) => {
      drawLine(
        line,
        modelViewerEl.queryHotspot('hotspot-dim-0'),
        modelViewerEl.queryHotspot(`hotspot-dim-${index + 1}`)
      );
    }
  );

  each(
    svgEl?.querySelector('.rullet')?.querySelectorAll('line'),
    (line, index) => {
      drawLine(
        line,
        modelViewerEl.queryHotspot(`hotspot-rullet-point-${index}`),
        modelViewerEl.queryHotspot(`hotspot-rullet-point-${index + 1}`) ||
          modelViewerEl.queryHotspot('hotspot-rullet-point-temp')
      );
    }
  );
}

type Point3D = { position: Vector3D; normal: Vector3D; uv: Vector2D };

interface ModelViewerDimensionsOptions {
  withDimensions?: boolean;
  withRuler?: boolean;
  units: Units;
}

function useModelViewerDimensions({
  withDimensions,
  withRuler,
  units
}: ModelViewerDimensionsOptions) {
  const { modelViewerEl, controls } = useModelViewer();

  const [svgEl, setSvgEl] = useState<SVGElement>(null);
  const [boxCenter, setBoxCenter] = useState(new Vector3());
  const [boxSize, setBoxSize] = useState(new Vector3());
  const [cameraPosition, setCameraPosition] = useState(new Vector3());
  const [rulletPoints, setRulletPoints] = useState<Point3D[]>([]);
  const [tempPoint, setTempPoint] = useState<Point3D>(null);

  const pointerData = useRef({
    startX: 0,
    startY: 0,
    time: 0,
    enabled: false,
    pressed: false
  });

  const side = useMemo(() => {
    const result = cameraPosition.clone().sub(boxCenter);
    result
      .divide(
        new Vector3(
          Math.abs(result.x) || 1,
          Math.abs(result.y) || 1,
          Math.abs(result.z) || 1
        )
      )
      .setY(-1);

    return result;
  }, [boxCenter, cameraPosition, withDimensions]);

  const points = useMemo(() => {
    return [
      side.clone(),
      side.clone().setX(side.x * -1),
      side.clone().setY(side.y * -1),
      side.clone().setZ(side.z * -1)
    ];
  }, [side]);

  const annotations = useMemo(() => {
    return [
      {
        point: points[1].clone().divide(new Vector3(2, 1, 1)),
        normal: side.clone().multiply(new Vector3(0, 0, 1)),
        value: boxSize.x * (unitsConfiguration[units]?.multiplier || 1)
      },
      {
        point: points[2].clone().divide(new Vector3(1, 2, 1)),
        normal: side.clone().multiply(new Vector3(1, 0, 1)),
        value: boxSize.y * (unitsConfiguration[units]?.multiplier || 1)
      },
      {
        point: points[3].clone().divide(new Vector3(1, 1, 2)),
        normal: side.clone().multiply(new Vector3(1, 0, 0)),
        value: boxSize.z * (unitsConfiguration[units]?.multiplier || 1)
      }
    ];
  }, [boxSize.x, boxSize.y, boxSize.z, points, side, units]);

  const rulletAnnotations = useMemo(() => {
    const getRulletPoint = (startPoint3d: Point3D, endPoint3d: Point3D) => {
      const startPos = toVector3(startPoint3d?.position);
      const endPos = toVector3(endPoint3d?.position);

      return {
        point: startPos.clone().add(endPos).divideScalar(2),
        value:
          startPos.distanceTo(endPos) *
          (unitsConfiguration[units]?.multiplier || 1)
      };
    };

    return rulletPoints.length > 0 && (rulletPoints[1] || tempPoint)
      ? [getRulletPoint(rulletPoints[0], rulletPoints[1] || tempPoint)]
      : [];
  }, [rulletPoints, tempPoint, units]);

  const onCameraChange = useCallback(() => {
    setCameraPosition(controls?.camera?.position?.clone() || new Vector3());

    drawSvg(svgEl, modelViewerEl);
  }, [controls?.camera?.position, modelViewerEl, svgEl]);

  useEffect(() => {
    const onLoad = () => {
      const dims = modelViewerEl.getDimensions();
      const center = modelViewerEl.getBoundingBoxCenter();
      setBoxSize(
        new Vector3(+dims.x.toFixed(4), +dims.y.toFixed(4), +dims.z.toFixed(4))
      );
      setBoxCenter(new Vector3(center.x, center.y, center.z));

      onCameraChange();
    };

    modelViewerEl?.addEventListener('camera-change', onCameraChange);

    modelViewerEl?.loaded
      ? onLoad()
      : modelViewerEl?.addEventListener('before-render', onLoad);

    return () => {
      modelViewerEl?.removeEventListener('camera-change', onCameraChange);
      modelViewerEl?.removeEventListener('before-render', onLoad);
    };
  }, [modelViewerEl, onCameraChange]);

  useEffect(() => {
    if (!withRuler) {
      pointerData.current.enabled = false;
      // setTempPoint(null);
      setRulletPoints([]);
      modelViewerEl?.removeAttribute('disable-tap');
      return;
    }

    const onDown = (e: MouseEvent) => {
      if (!withRuler) return;

      const { clientX: x, clientY: y } = e;

      pointerData.current = {
        startX: x,
        startY: y,
        time: Date.now(),
        enabled: true,
        pressed: true
      };

      e.stopPropagation();
    };

    const onMove = (e: MouseEvent) => {
      const { clientX: x, clientY: y } = e;

      const { startX, startY, time, pressed, enabled } = pointerData.current;

      // if (rulletPoints.length > 0 && !pressed) {
      //   const point = modelViewerEl?.positionAndNormalFromPoint(x, y);
      //   setTempPoint(point);
      // } else {
      //   setTempPoint(null);
      // }

      if (
        enabled === false ||
        Math.abs(startX - x) > 35 ||
        Math.abs(startY - y) ||
        Date.now() - time > 200
      ) {
        pointerData.current.enabled = false;
        return;
      }

      e.stopPropagation();
    };

    const onUp = (e: MouseEvent) => {
      pointerData.current.pressed = false;

      if (!pointerData.current.enabled) return;

      if (rulletPoints.length > 1) {
        setRulletPoints([]);
        // setTempPoint(null);
        return;
      }

      e.stopPropagation();

      const { clientX: x, clientY: y } = e;

      const point = modelViewerEl?.positionAndNormalFromPoint(x, y);

      setRulletPoints(point ? (rulletPoints) => [...rulletPoints, point] : []);
      // setTempPoint(null);
    };

    modelViewerEl?.setAttribute('disable-tap', '');

    modelViewerEl?.addEventListener('mousedown', onDown);
    modelViewerEl?.addEventListener('mousemove', onMove);
    modelViewerEl?.addEventListener('mouseup', onUp);
    modelViewerEl?.addEventListener('touchdown', onDown);
    modelViewerEl?.addEventListener('touchmove', onMove);
    modelViewerEl?.addEventListener('touchup', onUp);

    return () => {
      modelViewerEl?.removeEventListener('mousedown', onDown);
      modelViewerEl?.removeEventListener('mousemove', onMove);
      modelViewerEl?.removeEventListener('mouseup', onUp);
      modelViewerEl?.removeEventListener('touchdown', onDown);
      modelViewerEl?.removeEventListener('touchmove', onMove);
      modelViewerEl?.removeEventListener('touchup', onUp);
    };
  }, [modelViewerEl, rulletPoints.length, svgEl, withRuler]);

  useEffect(() => {
    setTimeout(() => drawSvg(svgEl, modelViewerEl));
  }, [boxSize, boxCenter, points, rulletAnnotations, svgEl, modelViewerEl]);

  return {
    boxCenter,
    boxSize,
    cameraPosition,
    side,
    points,
    annotations,
    rulletPoints,
    rulletAnnotations,
    tempPoint,
    setSvgEl
  };
}

export default useModelViewerDimensions;
