import { ForwardedRef, forwardRef, lazy } from 'react';
import { ModelViewerElement } from '@google/model-viewer';

import { ModelViewerProvider } from './hooks/useModelViewer';

import { ModelViewerRawProps } from './ModelViewerRaw';
import { ModelViewerDimensions } from './components/ModelViewerDimensions';
import { Units } from '../../types';

export type ModelViewerProps = ModelViewerRawProps & {
  withDimensions?: boolean;
  withRuler?: boolean;
  units?: Units;
};

const ModelViewerRaw = lazy(() => import('./ModelViewerRaw/ModelViewerRaw'));

function ModelViewer(
  props: ModelViewerProps,
  ref: ForwardedRef<ModelViewerElement>
) {
  const { children, withDimensions, withRuler, units, ...otherProps } = props;

  return (
    <ModelViewerProvider>
      <ModelViewerRaw {...otherProps} ref={ref}>
        <>
          <ModelViewerDimensions
            withDimensions={withDimensions}
            withRuler={withRuler}
            units={units || Units.CM}
          />

          {children}

          <style>
            {`
              :not(:defined) > * {
                display: none;
              }

              [slot^="hotspot-"] {
                --min-hotspot-opacity: 1;
              }
            `}
          </style>
        </>
      </ModelViewerRaw>
    </ModelViewerProvider>
  );
}

export default forwardRef(ModelViewer);
