import { IconsEnum } from '../../../../../assets/icons/types';
import { previewModelsKeys } from '../../../../../locales/keys';
import { ClassName } from '../../../../../types';

import { Translate } from '../../../../../helpers/Translate';

import { TooltipIconButtonHelper } from '../../../../../helpers/buttons/TooltipIconButtonHelper';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

interface ModelViewerHelperTooltipButtonProps {
  className?: ClassName;
  icon?: IconsEnum;
  tooltipPlacement?: TooltipPlacement;
  tooltipClassName?: ClassName;
  iconClassName?: ClassName;
}

function ModelViewerHelperTooltipButton({
  className
}: ModelViewerHelperTooltipButtonProps) {
  return (
    <TooltipIconButtonHelper
      icon={IconsEnum.QUESTION_MARK_CIRCLE_OUTLINE}
      className={
        className ||
        'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0'
      }
      iconClassName="h-6 w-6"
      tooltipPlacement={TooltipPlacement.TOP_START}
      tooltipClassName="rounded-md whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 max-w-lg"
      tooltipChildren={
        <div className="grid grid-cols-[auto_auto_auto] gap-x-4 gap-y-1 px-4 py-2 text-sm">
          <div className="text-gray-500 dark:text-gray-400">
            <Translate id={previewModelsKeys.zoom} />
          </div>
          <div>
            <Translate id={previewModelsKeys.mouseWheel} />
          </div>
          <div>
            <Translate id={previewModelsKeys.pinchZoomGesture} />
          </div>
          <div className="text-gray-500 dark:text-gray-400">
            <Translate id={previewModelsKeys.rotate} />
          </div>
          <div>
            <Translate id={previewModelsKeys.lmbMouseMove} />
          </div>
          <div>
            <Translate id={previewModelsKeys.dragWithOneFinder} />
          </div>
          <div className="text-gray-500 dark:text-gray-400">
            <Translate id={previewModelsKeys.pan} />
          </div>
          <div>
            <Translate id={previewModelsKeys.rmbMouseMove} />
          </div>
          <div>
            <Translate id={previewModelsKeys.dragWithTwoFingers} />
          </div>
          <div className="text-gray-500 dark:text-gray-400">
            <Translate id={previewModelsKeys.focus} />
          </div>
          <div>
            <Translate id={previewModelsKeys.clickOnModel} />
          </div>
          <div>
            <Translate id={previewModelsKeys.tapOnModel} />
          </div>
          <div className="text-gray-500 dark:text-gray-400">
            <Translate id={previewModelsKeys.fitToScreen} />
          </div>
          <div>
            <Translate id={previewModelsKeys.clickOutsideModel} />
          </div>
          <div>
            <Translate id={previewModelsKeys.tapOutsideModel} />
          </div>
        </div>
      }
    />
  );
}

export default ModelViewerHelperTooltipButton;
