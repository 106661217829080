import { Vector3 } from 'three/src/math/Vector3';

interface Vector3D {
  x: number;
  y: number;
  z: number;
}

export const toVector3 = (vector: Vector3D) =>
  new Vector3(vector?.x, vector?.y, vector?.z);
