import { useCallback } from 'react';

import { downloadFile } from '../../../../../../../utils/downloadFile';

export type DownloadPrevieModelInput = {
  file: string;
  name: string;
};

function useDownloadModelButton() {
  const downloadModel = useCallback<(input: DownloadPrevieModelInput) => void>(
    (input) => {
      downloadFile(input);
    },
    []
  );

  return { downloadModel };
}

export default useDownloadModelButton;
