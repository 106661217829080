import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useMemo,
  useRef
} from 'react';

import { ModelViewerContextType } from './useModelViewer.types';
import { ModelViewerElement } from '@google/model-viewer';

const ModelViewerContext = createContext<ModelViewerContextType>({
  modelViewerRef: null,
  modelViewerEl: null,
  controls: null,
  scene: null,
  setModelViewerEl: () => {
    console.log('error: setModelViewerEl should be initialized');
  }
});

interface ModelViewerProps {
  children: ReactNode;
}

export function ModelViewerProvider({ children }: ModelViewerProps) {
  const [modelViewerEl, setModelViewerEl] = useState<ModelViewerElement | null>(
    null
  );
  const modelViewerRef = useRef<ModelViewerElement | null>(null);

  const modelViewerContext = useMemo<ModelViewerContextType>(() => {
    function getHiddenProp<T = unknown>(prop: string): T {
      if (!modelViewerEl) return null;

      return modelViewerEl[
        Object.getOwnPropertySymbols(modelViewerEl).find(
          (x) => x.description === prop
        )
      ];
    }

    return {
      modelViewerRef,
      modelViewerEl,
      controls: getHiddenProp('controls'),
      scene: getHiddenProp('scene'),
      getHiddenProp,
      setModelViewerEl
    };
  }, [modelViewerEl]);

  return (
    <ModelViewerContext.Provider value={modelViewerContext}>
      {children}
    </ModelViewerContext.Provider>
  );
}

export const useModelViewer = () => {
  return useContext(ModelViewerContext);
};
