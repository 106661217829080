import React, {
  ReactNode,
  MouseEvent,
  useCallback,
  CSSProperties,
  Fragment,
  useState
} from 'react';

import { IconsEnum } from '../../../assets/icons/types';
import { ClassName } from '../../../types';

import { Icon } from '../../Icon';

import { Tooltip } from '../../tooltips/Tooltip';

import { TooltipPlacement } from '../../tooltips/tooltipsConstants';

type TooltipIconButtonHelperOnClick = (
  e: MouseEvent<HTMLButtonElement>
) => void;

type TooltipIconButtonHelperProps = {
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  disabled?: boolean;
  style?: CSSProperties;
  blurOnMouseLeave?: boolean;
  tooltipClassName?: ClassName;
  tooltipPlacement?: TooltipPlacement;
  tooltipChildren: ReactNode;
  onClick?: TooltipIconButtonHelperOnClick;
  children?: ReactNode;
  tooltipInteractive?: boolean;
};

const tooltipDelay: [number, number] = [300, 0];

function TooltipIconButtonHelper({
  children,
  icon,
  iconClassName,
  className,
  disabled,
  style,
  blurOnMouseLeave,
  tooltipClassName,
  tooltipPlacement,
  tooltipChildren,
  onClick,
  tooltipInteractive
}: TooltipIconButtonHelperProps) {
  const [referenceTooltipElement, setReferenceTooltipElement] =
    useState<HTMLButtonElement | null>(null);

  const handleClick = useCallback<TooltipIconButtonHelperOnClick>(
    (e) => {
      e.preventDefault();
      onClick?.(e);
    },
    [onClick]
  );

  const handleMouseLeave = useCallback(
    (event) => (event.target as HTMLButtonElement)?.blur(),
    []
  );

  return (
    <Fragment>
      <button
        type="button"
        className={className}
        disabled={disabled}
        style={style}
        ref={setReferenceTooltipElement}
        onClick={handleClick}
        onMouseLeave={blurOnMouseLeave ? handleMouseLeave : undefined}
      >
        {icon ? <Icon className={iconClassName} icon={icon} /> : children}
      </button>
      <Tooltip
        interactive={tooltipInteractive}
        withArrow
        referenceElement={referenceTooltipElement}
        placement={tooltipPlacement}
        className={tooltipClassName}
        maxWidth="none"
        delay={tooltipDelay}
      >
        {tooltipChildren}
      </Tooltip>
    </Fragment>
  );
}

export default TooltipIconButtonHelper;
