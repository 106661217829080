import cl from 'classnames';

import { IconsEnum } from '../../../../../assets/icons/types';
import { FileUrl, Units } from '../../../../../types';

import { useModelViewerModalButton } from './hooks/useModelViewerModalButton';

import { DownloadModelButton } from '../../buttons/DownloadModelButton.tsx';
import { ModelViewerHelperTooltipButton } from '../../buttons/ModelViewerHelperTooltipButton';

import {
  ModelViewer,
  ModelViewerProps
} from '../../../../../helpers/ModelViewer';
import {
  SimpleModalButton,
  SimpleModalButtonDefaultProps
} from '../../../../../helpers/buttons/SimpleModalButton';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

type ModelViewerModalButtonProps = Pick<
  SimpleModalButtonDefaultProps,
  | 'className'
  | 'i18nText'
  | 'i18nTitle'
  | 'title'
  | 'icon'
  | 'iconClassName'
  | 'tooltipPlacement'
  | 'tooltipI18nText'
  | 'modalSize'
> &
  Pick<ModelViewerProps, 'cameraControls'> & {
    withDownloadButton?: boolean;
    model?: {
      file: FileUrl;
      name: string;
    };
    initialModelIndex?: number;
    models?: {
      file: FileUrl;
      name: string;
    }[];
  };

function ModelViewerModalButton({
  className,
  title,
  i18nTitle,
  i18nText,
  icon,
  iconClassName,
  tooltipPlacement,
  tooltipI18nText,
  modalSize,
  model,
  initialModelIndex,
  models,
  cameraControls,
  withDownloadButton
}: ModelViewerModalButtonProps) {
  const {
    handlePrevItem,
    handleNextItem,
    activeItem,
    modelViewerRef,
    multiplyItems,
    withDimensions,
    toggleWithDimensions,
    withRuler,
    toggleWithRuler,
    units,
    toggleUnits
  } = useModelViewerModalButton({
    model,
    initialModelIndex,
    models
  });

  return (
    <SimpleModalButton
      className={className}
      i18nTitle={i18nTitle}
      title={title ?? activeItem?.name}
      icon={icon}
      iconClassName={iconClassName}
      i18nText={i18nText}
      tooltipPlacement={tooltipPlacement}
      tooltipI18nText={tooltipI18nText}
      childrenClassName="w-full h-full relative"
      addModalClassName="h-full"
      modalSize={modalSize || 'full'}
      buttonsContainerClassName="p-0"
      withoutCancelButton
      withoutSubmitButton
    >
      <ModelViewer
        src={activeItem?.file}
        cameraControls={cameraControls}
        className="w-full h-full"
        ref={modelViewerRef}
        withDimensions={withDimensions}
        withRuler={withRuler}
        units={units}
      />
      {/* Left */}
      <div className="absolute left-0 inset-y-0 z-10">
        {multiplyItems && (
          <PureIconButtonHelper
            className="opacity-0 hover:opacity-100 h-full p-10 lg:p-20 bg-black/30 focus:outline-none hidden md:block"
            iconClassName="w-10 h-10"
            icon={IconsEnum.CHEVRON_LEFT}
            onClick={handlePrevItem}
          />
        )}
      </div>
      {/* Right */}
      <div className="absolute right-0 inset-y-0 z-10">
        {multiplyItems && (
          <PureIconButtonHelper
            className="opacity-0 hover:opacity-100 h-full p-10 lg:p-20 bg-black/30 focus:outline-none hidden md:block"
            iconClassName="w-10 h-10"
            icon={IconsEnum.CHEVRON_RIGHT}
            onClick={handleNextItem}
          />
        )}
      </div>
      {/* Left bottom */}
      <div className="absolute bottom-4 left-4 z-10">
        <div className="relative flex items-center justify-center gap-2">
          <ModelViewerHelperTooltipButton
            icon={IconsEnum.KEYBOARD_SOLID}
            className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            iconClassName="h-6 w-6"
          />

          <PureTooltipIconButtonHelper
            className={cl(
              'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0',
              {
                'hover:bg-gray-200 dark:hover:bg-gray-700': !withDimensions,
                'bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-500':
                  withDimensions
              }
            )}
            icon={IconsEnum.CUBE_TRANSPARENT_OUTLINE}
            iconClassName="h-6 w-6"
            tooltipI18nText={
              withDimensions ? words.hideDimensions : words.showDimensions
            }
            tooltipPlacement={TooltipPlacement.TOP}
            onClick={toggleWithDimensions}
          />

          <PureTooltipIconButtonHelper
            className={cl(
              'py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0',
              {
                'hover:bg-gray-200 dark:hover:bg-gray-700': !withRuler,
                'bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-500':
                  withRuler
              }
            )}
            icon={IconsEnum.RULERS_SOLID}
            iconClassName="h-6 w-6"
            tooltipI18nText={withRuler ? words.hideRuler : words.showRuler}
            tooltipPlacement={TooltipPlacement.TOP}
            onClick={toggleWithRuler}
          />

          <PureTooltipIconButtonHelper
            className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white focus:ring-offset-0 hover:bg-gray-200 dark:hover:bg-gray-700"
            icon={
              units === Units.IN
                ? IconsEnum.UNITS_METRIC_IN_SOLID
                : IconsEnum.UNITS_METRIC_CM_SOLID
            }
            iconClassName="h-6 w-6"
            tooltipI18nText={words.changeUnits}
            tooltipPlacement={TooltipPlacement.TOP}
            onClick={toggleUnits}
          />

          {withDownloadButton && (
            <DownloadModelButton
              icon={IconsEnum.DOWNLOAD_SOLID}
              className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              iconClassName="h-6 w-6"
              model={activeItem}
            />
          )}
        </div>
      </div>
      {/* Right bottom */}
      <div className="absolute bottom-4 right-4 z-10">
        <div className="relative flex items-center justify-center gap-2">
          {multiplyItems && (
            <PureIconButtonHelper
              className="md:hidden py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              iconClassName="h-6 w-6"
              icon={IconsEnum.CHEVRON_LEFT}
              onClick={handlePrevItem}
            />
          )}
          {multiplyItems && (
            <PureIconButtonHelper
              className="md:hidden py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              iconClassName="h-6 w-6"
              icon={IconsEnum.CHEVRON_RIGHT}
              onClick={handleNextItem}
            />
          )}
        </div>
      </div>
    </SimpleModalButton>
  );
}

export default ModelViewerModalButton;
