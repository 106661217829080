import { useCallback, useState } from 'react';

import { LocalStorageKey } from './useLocalStorageProxyState.types';

function useLocalStorageProxyState<ValueType extends Record<string, any>>(
  key: LocalStorageKey,
  initialValue: ValueType
) {
  const [value, setStoredValue] = useState<ValueType>(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);

      return initialValue;
    }
  });

  const localStorageProxyHandler: ProxyHandler<ValueType> = {
    get: useCallback(
      (target: ValueType, targetKey: string) => {
        const storedValue = localStorage.getItem(key);

        return storedValue !== null
          ? JSON.parse(storedValue)?.[targetKey]
          : value?.[targetKey];
      },
      [key, value]
    ),

    ownKeys: () => {
      const storedValue = localStorage.getItem(key);

      const keys =
        storedValue !== null
          ? Object.keys(JSON.parse(storedValue))
          : Object.keys(value);

      return Array.from(new Set(keys));
    },

    has: (target, targetKey) => {
      const storedValue = localStorage.getItem(key);

      return storedValue !== null
        ? targetKey in JSON.parse(storedValue)
        : targetKey in value;
    },

    getOwnPropertyDescriptor: (target, targetKey) => {
      const storedValue = localStorage.getItem(key);
      const computedValue =
        storedValue !== null ? JSON.parse(storedValue) : value;

      if (targetKey in computedValue) {
        return {
          enumerable: true,
          configurable: true,
          value: computedValue[targetKey]
        };
      }

      return undefined;
    }
  };

  const localStorageProxy = new Proxy(
    {} as ValueType,
    localStorageProxyHandler
  );

  const setValue = (
    value: ValueType,
    replacer?: (this: any, key: string, value: any) => any
  ) => {
    try {
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(value, replacer));
      }

      setStoredValue(value);
    } catch (error) {
      console.log(error);
    }
  };

  return [localStorageProxy, setValue] as const;
}

export default useLocalStorageProxyState;
