import map from 'lodash/map';

import { useModelViewerDimensions } from './hooks/useModelViewerDimensions';

import { ModelViewerAnnotation } from '../ModelViewerAnnotation';

import { vectorToString } from '../../utils/vectorToString';
import { Units } from '../../../../types';
import { Translate } from '../../../Translate';
import { words } from '../../../../locales/keys';

interface ModelViewerDimensionsProps {
  withDimensions?: boolean;
  withRuler?: boolean;
  units: Units;
}

function ModelViewerDimensions({
  withDimensions,
  withRuler,
  units
}: ModelViewerDimensionsProps) {
  const {
    points,
    annotations,
    rulletPoints,
    rulletAnnotations,
    tempPoint,
    setSvgEl
  } = useModelViewerDimensions({
    withDimensions,
    withRuler,
    units
  });

  return (
    <>
      {withDimensions && (
        <>
          {map(points, (point, index) => (
            <ModelViewerAnnotation
              key={index}
              hotspotName={`dim-${index}`}
              position={vectorToString(point)}
              className="pointer-events-none"
            />
          ))}

          {map(annotations, (annotation, index) => (
            <ModelViewerAnnotation
              key={index}
              hotspotName={`annotation-${index}`}
              position={vectorToString(annotation.point)}
              normal={vectorToString(annotation.normal)}
              className="pointer-events-none px-2 py-1 bg-black rounded-md text-white"
            >
              {+(annotation.value * 100).toFixed(2)}{' '}
              <Translate
                id={units === Units.IN ? words.units.in : words.units.cm}
              />
            </ModelViewerAnnotation>
          ))}
        </>
      )}

      {withRuler && (
        <>
          {map(rulletPoints, (point, index) => (
            <ModelViewerAnnotation
              key={index}
              hotspotName={`rullet-point-${index}`}
              position={vectorToString(point.position)}
              normal={vectorToString(point.normal)}
              className="pointer-events-none border-4 rounded-full border-black block"
              absolutePosition
            />
          ))}

          {tempPoint && (
            <ModelViewerAnnotation
              hotspotName="rullet-point-temp"
              position={vectorToString(tempPoint.position)}
              normal={vectorToString(tempPoint.normal)}
              className="pointer-events-none"
              absolutePosition
            />
          )}

          {map(rulletAnnotations, (annotation, index) => (
            <ModelViewerAnnotation
              key={index}
              hotspotName={`rullet-annotation-${index}`}
              position={vectorToString(annotation.point)}
              // normal={vectorToString(annotation.normal)}
              className="pointer-events-none px-2 py-1 bg-black rounded-md text-white"
              absolutePosition
            >
              {+(annotation.value * 100).toFixed(2)}{' '}
              <Translate
                id={units === Units.IN ? words.units.in : words.units.cm}
              />
            </ModelViewerAnnotation>
          ))}
        </>
      )}

      <svg
        ref={setSvgEl}
        width="100%"
        height="100%"
        xmlns="http://www.w3.org/2000/svg"
        className="pointer-events-none"
      >
        {withDimensions && (
          <g className="dim">
            {map(annotations, (_, index) => (
              <line
                key={index}
                className="stroke-black stroke-2"
                strokeDasharray="20 10"
              />
            ))}
          </g>
        )}

        {withRuler && (
          <g className="rullet">
            {map(rulletAnnotations, (_, index) => (
              <line key={index} className="stroke-black stroke-2" />
            ))}
          </g>
        )}
      </svg>
    </>
  );
}

export default ModelViewerDimensions;
